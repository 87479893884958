.bar {
  padding: 8px 16px;
  /* background-color: var(--bg-color-primary); */
  background-color: #2f1b26;
  color: var(--text-color);
  min-height: 18px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  text-transform: uppercase;

  .comparision {
    padding: 3px 6px;
    border-radius: 3px;
    /* background-color: var(--primary-color); */
    background-color: #0000008c;
    /* color: var(--text-color-dark); */
    color: #858585;
    font-size: 0.75em;
    text-align: right;
    text-transform: capitalize;
    font-weight: 600;
  }
}

.bar p {
  font-size: 0.75em;
  font-weight: 600;

  .count {
    padding: 2px 6px;
    color: var(--text-color-constrast);
    text-transform: capitalize;
    background-color: #ffffff1f;
    border-radius: 3px;
    min-width: 14px;
    display: inline-block;
    text-align: center;
    font-weight: 400;
  }

}