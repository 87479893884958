html {
  margin: 0;
  padding: 0;
  height: 100%;
}

body {
  padding: 0;
  height: inherit;
  overflow: hidden;
}

#root {
  position: relative;
  margin: 0;
  padding: 0;
  height: inherit;
}
.app {
  height: 100%;
  display: grid;
  grid-template-areas: 'header''main''footer';
  grid-template-rows: 120px 1fr 30px;
  overflow: hidden;

  .main {
    position: relative;
    /* background-color: var(--bg-color-theme); */
    background-color: #060606;
    grid-area: main;
    display: flex;
  }
}

.app::before, .main::before {
  position: absolute;
  left: -250px;
  top: 250px;
  right: auto;
  bottom: auto;
  z-index: 1;
  width: 500px;
  height: 500px;
  border-radius: 1000px;
  background-image: linear-gradient(45deg, #059DFF, #36C6F0);
  opacity: 0.1;
  filter: blur(100px);
  content: "";
  pointer-events: none;
}

.editor-area {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;

  .editor-area-left {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .editor-area-right {
    position: relative;
    height: inherit;
    display: flex;
    flex-direction: column;
  }
}

.editor-area-left .react-split__pane .top,
.editor-area-left .react-split__pane .bottom {
  position: relative;
  display: flex;
  flex-direction: column;
}

.editor-area .monaco-editor, .monaco-editor-background{
  /* background-color:  var(--bg-color-secondary); */
  background-color: #1a1619!important;

  color: var(--text-color);
  font-size: 15px;
}

.editor-area .monaco-editor .margin-view-overlays {
  /* background-color:  var(--bg-color-secondary); */
  background-color: #2f1b2675;
}

/* Button style */
.btn {
  color: var(--text-color);
  width: 100px;
  cursor: pointer;
  outline: none;
  transition: 1s ease-in-out;

  svg {
    position: absolute;
    left: 0;
    top: 0;
    fill: none;
    stroke: #fff;
    stroke-dasharray: 150 480;
    stroke-dashoffset: 150;
    transition: 1s ease-in-out;
  }
}

.btn:hover {
  transition: 1s ease-in-out;
  background: #4F95DA;
}

.btn:hover svg {
  stroke-dashoffset: -480;
}

.btn span {
  color: white;
  font-size: 14px;
  font-weight: 100;
}

/* DropDown  */

.pretemplate-dropdown {
  position: absolute;
  top: 6px;
  right: 24px;
}

.toolbar {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
  z-index: 999;
  margin-top: 6px;

  .actions {
    position: absolute;
    top: 6px;
    right: 24px;
    display: flex;
    column-gap: 16px;
  }
}

.toolbar select {
  padding: 2px;
  border: 0;
  outline: none;
  border-radius: 4px;
  background-color: var(--bg-color-secondary);
  color: var(--text-color);
  font-size: 0.9em;
  line-height: 20;
  box-shadow: none;
  border: 2px solid;
  transition: border-color 0.3s ease-in-out;
}

.toolbar label.toggle, .toggleSwtich {
  display: flex;
  align-items: center;
  gap: 10px;
}

.toolbar label.toggle span, .toggleSwtich span {
  color: white;
  opacity: 0.5;
  user-select: none;
  font-size: 0.876em;
  text-transform: capitalize;
}

.toolbar .react-toggle-track, .toggleSwtich .react-toggle-track {
  width: 44px;
  height: 20px;

  svg, .react-toggle-track-x {
    width: 8px;
    height: 10px;
  }
}

.react-toggle--checked .react-toggle-track {
  background-color: var(--primary-color)!important;
}

.toolbar .react-toggle-thumb, .toggleSwtich .react-toggle-thumb {
  width: 18px;
  height: 18px;
}

/* React Splitter */

.react-split__sash--vertical, .react-split__sash--horizontal {
  /* background-color: var(--bg-color-primary); */
  background-color: #2f1b26;
  z-index: 999;
}

.react-split__sash--vertical:hover, .react-split__sash--horizontal:hover {
  background-color: var(--primary-color);
  /* background-color: #2f1b26; */
}

/* Animations */

.fade-in {
  opacity: 0;
  animation: fadeIn 0.3s ease-in-out forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes highlight {
  0% { border-color: var(--primary-color); }
  50% { border-color: transparent; }
  100% { border-color: var(--primary-color); }
}
.highlight {
  animation: highlight 2s infinite;
}
