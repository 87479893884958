.footer {
  background-color: var(--primary-color);
  grid-area: footer;
  z-index: 9;
  display: flex;
  align-items: center;
  padding: 0 16px;
}

.footer .left {
  display: flex;
  align-items: center;
  column-gap: 4px;
  flex: 1;
  height: inherit;
}

.footer .right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: inherit;

  .company-logo {
    width: 24px;
    margin-bottom: -8px;
  }
}

.footer p {
  margin: 0;
  margin-bottom: 2px;
  color: var(--text-color-contrast);
  font-size: 0.785em;
}

.footer a {
  color: var(--text-color-contrast);
  text-decoration: none;
}

.footer a:hover {
  text-decoration: underline;
}