.overlay {
  position: absolute;
  top: 0;
  left: 5px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  z-index: 999;
  backdrop-filter: blur(3px);
}

.overlay-content {
  position: relative;
  color: white;
  width: 100%;
  height: 100%;
  padding: 20px;
}

.overlay-desc{
  margin-top: 10px;
  background-color: var(--bg-color-theme);
  opacity: 0.9;

  min-height: 50px;
  padding: 20px;
  color: var(--text-color-error);
}

.heading {
  padding: 0;
  margin: 0;
  font-size: 1.6em;
  font-weight: 400;
  color: var(--text-color-error);
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  background: none;
  border: none;
  color: var(--text-color-contrast);
}

.close-button span {
  font-size: 1.4em;
}
