:root {

  --app-font: 'Inter', sans-serif;

  --text-color: #bbbdbf;
  --text-color-light: #999;
  --text-color-contrast: #fff;
  --text-color-dark: #000;
  --text-color-highlight: #ff0000;
  --text-color-error: #dd444a;

  --bg-color-primary: #333333;
  --bg-color-secondary: #1e2429;
  --bg-color-theme: #1e1e1e;
  --bg-color-dark: #2c3137;

  --border-color: #333;

  --primary-color: #0071d1;

  --box-shadow-1: 0px 2px 4px 2px #171a1d;
  --box-shadow-2: 0 8px 16px rgba(0, 0, 0, 0.2);

}

#root {
  position: relative;
}
