
.App-header {
  position: relative;
  grid-area: header;
  display: flex;
  align-items: center;
  /* background-color: var(--bg-color-theme); */
  background-color: #060606;
  color: var(--text-color-contrast);
  padding: 0 18px;
  z-index: 9;
}

.App-header .left {
  display: grid;
  align-items: center;
  height: inherit;
  gap: 14px;

  .description {
    font-size: 0.9rem;
    color: var(--text-color);
  }
}

.App-header .left .logo-container {
  display: flex;
  align-items: center;
  height: inherit;
  gap: 10px;
}

.App-header .left img{
  height: 24px;
  width: 24px;
  filter: contrast(0.6);
}

.App-header .left h1 {
  font-size: 1.8rem;
  /* color: var(--text-color); */
  color: white;
  font-family: "Oswald";
  font-weight: 400;
  letter-spacing: 1px;
  text-transform: capitalize;

  span {
    background: linear-gradient(95deg, #059DFF 15%, #6549D5 45%, #E33FA1 75%, #FB5343 100%) 98%/200% 100%;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-style: italic;
  }
}


.App-header .right {
  display: grid;
  align-items: center;
  justify-content: flex-end;
  flex: auto;
  gap: 2px;

  .menu-container {
    display: flex;
    justify-content: flex-end;
  }
}

/* Menu Style */
 .header-menu {
    height: 100%;
    list-style: none;
    margin: 0;
    padding: 0;
}

.header-menu .header-menuItem {
    align-items: stretch;
    display: inline-flex;
    margin: 0 8px;

    &:last-child {
      margin-right: 0;
    }
}

.header-menu .header-menuItem > a {
  font-weight: 'Inter', sans-serif;
    font-size: 16px;
    align-items: center;
    color: var(--text-color);
    font-weight: 400;
    text-decoration: none;
    padding: 8px 0px;
    transition: color 0.3s ease;
}

.header-menu .header-menuItem > a:hover {
  color: var(--primary-color);
}